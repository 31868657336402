import React from 'react'
import cx from 'classnames'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/veganska-strava-je-zdravejsi.jpg'
import styles from './post-grid.module.css'

const VeganskaStravaJeZdravejsi = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Veganská strava je zdravější. Nebo ne? | NutritionPro'}
        description={
          'Veganství je styl výživy, který získává na popularitě, ať už z etických, zdravotních nebo environmentálních důvodů. Dnes už běžně najdete všemožné náhražky živočišných výrobků od hovězího masa přes mléčné výrobky až po vejce. Dokonce i restaurace se tomuto výživovému směru přizpůsobují a začínají do menu zařazovat více rostlinných alternativ. Vznikají také restaurace, které nabízí pouze rostlinné menu. Alternativy živočišných výrobků také chutnají lépe než dřív, protože se stále vylepšují a některé už jsou od svých protějšků k nerozeznání. Rostlinná strava sice není úplně mainstream, každý den se však stává více akceptovanou.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/veganska-strava-je-zdravejsi">
            Veganská strava je zdravější. Nebo ne?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Veganská strava je zdravější. Nebo ne?"
              date="17.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Veganství je styl výživy, který <b>získává na popularitě</b>, ať
                už z etických, zdravotních nebo environmentálních důvodů. Dnes
                už <b>běžně najdete všemožné náhražky živočišných výrobků</b> od
                hovězího masa přes mléčné výrobky až po vejce. Dokonce i
                restaurace se tomuto výživovému směru přizpůsobují a začínají do
                menu zařazovat <b>více rostlinných alternativ</b>. Vznikají také
                restaurace, které nabízí pouze rostlinné menu. Alternativy
                živočišných výrobků také <b>chutnají lépe než dřív</b>, protože
                se stále vylepšují a některé už jsou od svých protějšků{' '}
                <b>k nerozeznání</b>. Rostlinná strava sice není úplně
                mainstream, každý den se však stává <b>více akceptovanou</b>.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="vegetariánské jídlo"
              />

              <p className={styles.postText}>
                Popularita veganské stravy stále roste. V roce 2020 činily tržby
                z prodeje mléčných výrobků a masa rostlinného původu více než{' '}
                <b>29 miliard dolarů</b>, to znamená, že rostlinné alternativy
                masa a mléčných výrobků již tvoří téměř 8 % celosvětového trhu s
                tzv. “bílkovinnými potravinami”. Očekává se, že do roku 2030
                vzroste tento prodej až pětinásobně {'('}
                <a
                  href="https://www.bloomberg.com/news/articles/2021-08-11/plant-based-food-sales-to-increase-fivefold-by-2030-bi-says#xj4y7vzkg"
                  target="_blank"
                >
                  zdroj
                </a>
                {')'}. Je tedy jasné, že veganská strava se stává{' '}
                <b>čím dál víc populární</b> a že její sláva ještě poroste.
              </p>

              <p className={styles.postText}>
                Jedním z důvodů, proč lidé přecházejí na veganskou stravu, je
                přesvědčení, že je <b>zdravější než jiné druhy stravy</b>. Je
                ale toto tvrzení opravdu pravdivé?
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Může za nadváhu konzumace sacharidů? Dozvíte se v{' '}
                <a
                  href="https://nutritionpro.cz/blog/muze-za-nadvahu-konzumace-sacharidu/"
                  target="_blank"
                >
                  tomto článku
                </a>
                .
              </p>

              <h5 className={styles.postTitle}>Veganství</h5>
              <p className={styles.postText}>
                Veganství patří mezi <b>alternativní styly výživy</b>, je to
                tedy styl, který se nějak odlišuje od obecně doporučovaných
                zásad stravování. Veganská strava se skládá{' '}
                <b>pouze z potravin rostlinného původu</b> a odmítá veškeré
                živočišné produkty, tedy maso a masné výrobky, ryby, mléko a
                mléčné výrobky, vejce a med.
              </p>

              <h5 className={styles.postTitle}>Výhody veganské stravy</h5>
              <p className={styles.postText}>
                Mezi hlavní výhody veganské stravy patří{' '}
                <b>vyšší příjem vlákniny</b>, které většina populace ČR
                nepřijímá dostatek, tedy 25-30 gramů na den. Vegani nekonzumují
                živočišný tuk, tím pádem <b>se může snížit</b> i příjem{' '}
                <b>nasycených mastných kyselin a cholesterolu</b>. Dalším plusem
                je{' '}
                <b>vyšší příjem vody a určitých vitaminů a minerálních látek</b>
                , a to díky vyššímu příjmu ovoce a zeleniny. Možným benefitem je{' '}
                i <b>snížení energetického příjmu</b>, což samozřejmě není
                podmínkou. Pokud se ale rozhodnete zaměřit se na konkrétní styl
                stravování, obvykle se to neobejde bez přemýšlení nad tím,{' '}
                <b>jak se stravujete</b> a zhodnocením svého jídelníčku. Díky
                tomu můžete ve výsledku zlepšit své stravovací návyky a třeba
                snížit kalorický příjem a případně zhubnout, pokud je zrovna to
                vaším cílem.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Víte, s jakým sportem{' '}
                <a
                  href="https://nutritionpro.cz/blog/s-jakym-sportem-nejrychleji-zhubnu/"
                  target="_blank"
                >
                  zhubnete nejrychleji
                </a>
                ?
              </p>

              <p className={styles.postText}>
                Přechod na veganskou stravu se obvykle pojí také se{' '}
                <b>zamyšlením se nad celkovým životním stylem</b>. Veganskou
                cestu může tedy doprovázet třeba snížení či vynechání alkoholu,
                fyzická aktivita, dostatečné množství spánku a adekvátní pitný
                režim. S veganstvím se samozřejmě pojí i <b>další výhody</b>, my
                se dnes ale zaměříme <b>pouze na nutriční stránku</b> veganství.
              </p>

              <h5 className={styles.postTitle}>Rizika veganské stravy</h5>
              <p className={styles.postText}>
                Jelikož veganství vybočuje z určitých norem, je potřeba se o
                tomto směru <b>dostatečně informovat</b> před tím, než se do něj
                naplno pustíte. Pozor byste si měli dát hlavně na potenciální{' '}
                <b>nedostatek esenciálních aminokyselin</b>. Důvodem je to, že
                zdrojem plnohodnotných bílkovin jsou téměř jen potraviny
                živočišného původu (až na výjimky). To znamená, že obsahují v
                dostatečném množství všechny esenciální aminokyseliny. Ty{' '}
                <b>lze</b> samozřejmě <b>získat rostlinnou stravou</b>, jen se
                musíte více snažit a potraviny kombinovat (např. luštěniny +
                obiloviny). Příjem výhradně rostlinných potravin také nese
                riziko{' '}
                <b>
                  nedostatku vápníku, železa, zinku a také vitaminu B12,
                  vitaminu D a omega 3 mastných kyselin
                </b>
                . Abyste nedostatku předešli, je opět nutné se nad tím, co jíte,
                pořádně zamyslet, případně některé z těchto mikronutrientů začít
                brát v podobě <b>doplňků stravy</b>. Jsou totiž pro{' '}
                <b>normální fungování</b> těla <b>zásadní</b>. Je také nutné
                dávat pozor na <b>složení veganských náhražek</b> masa a jiných
                živočišných produktů, které{' '}
                <b>nemusí být zdaleka tak výživné</b>, jako jejich živočišné
                protějšky. Celkově je nutné dbát na <b>kvalitu a pestrost</b>{' '}
                veganského jídelníčku. Veganská strava <b>není vhodná</b> pro{' '}
                <b>těhotné a kojící</b> ženy a <b>děti</b>.
              </p>

              <h5 className={styles.postTitle}>
                Je tedy veganství zdravější než běžná strava?
              </h5>
              <p className={styles.postText}>
                Krátce: Ne. Veganská strava <b>není automaticky zdravější</b>{' '}
                než jiné typy stravování. Každý způsob stravování má své{' '}
                <b>výhody a nevýhody</b> a pokud se někdo rozhodne pro veganskou
                stravu, měl by se o nich informovat a být si jich vědom. Každý
                vegan by měl dbát na to, aby si svůj jídelníček sestavil tak,
                aby obsahoval <b>všechny potřebné živiny</b>. Veganská strava
                může být pro určité osoby <b>prospěšná</b>, pokud je správně{' '}
                <b>sestavena</b>, ale může také vést ke{' '}
                <b>zhoršení zdravotního stavu</b>, pokud neobsahuje všechny
                potřebné živiny. Proto by si každý měl před rozhodnutím o změně
                stravování pečlivě zvážit všechna pro i proti a konzultovat svůj
                plán s <b>odborníkem na výživu</b>.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default VeganskaStravaJeZdravejsi
